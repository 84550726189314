import { upperCase } from 'lodash'
import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    // const app = getCurrentInstance()
    // const { $toast } = app!.appContext.config.globalProperties
    const router = useRouter()
    const store = useStore()
    const data = ref([{
      judul: 'Excel Report',
      description: 'Laporan transaksi yang berbentuk excel yang dapat Anda download dan analisa secara manual',
      type: 'report',
      assets: require('@/assets/img/excel-report.png'),
      routeName: 'reporting-excel',
    }, {
      judul: 'Advanced Report',
      description: 'Laporan transaksi admin dan agent dalam bentuk yang lebih modern untuk memudahkan analisis Anda.',
      type: 'report',
      assets: require('@/assets/img/advance-report.png'),
      routeName: 'reporting-print',
    }
    ])
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const direksiCard = ref({
      judul: 'Dashboard Direksi',
      description: 'Laporan harian dan overview transaksi dalam sistem HJEX',
      assets: require('@/assets/img/direksi-report.png'),
      url: 'https://metabase.hjex.co.id/public/dashboard/f5078f0a-907c-4b99-b02a-f078c01f93ba'
    })
    const activeUserGuid = ref(store.state.appActiveUser.guid.toUpperCase())
    const direksiGuid = ref('3A0F133C-90B6-4B80-AE39-5FF5A82C8534')
    const openReportType = (reportType: any) => {
      router.push({ name: reportType })
    }

    const goDashboardMetabase = () => {
      window.open(direksiCard.value.url, '_blank')
    }

    return {
      data,
      openReportType,
      direksiCard,
      activeUserGuid,
      direksiGuid,
      goDashboardMetabase
    }
  }
}
